import React from "react";
import MapMarker from "./mapMarker";
import MarkerClusterGroup from "react-leaflet-markercluster";
//import MarkerClusterGroup from "react-leaflet-cluster";
import { spiderfyShapePositions } from "./helpers/spiderfyShapePositions";
import { LayerGroup } from "react-leaflet";
import { isVisibleInMap } from "../../helpers/filterHelpers";
import { useRef } from "react";

const MapMarkerClusterGroup = ({
	museumsList,
	map,
	tooltipFixUpdateForcer,
	currentMarkerData,
	setCurrentMarkerData,
	allMarkersRef
}) => {
	const prevDocumentRef = useRef();
	
	console.log("allMarkersRef");
	console.log(allMarkersRef.current);
	return (
		<MarkerClusterGroup spiderfyShapePositions={spiderfyShapePositions}>
			{museumsList.length &&
				museumsList.map(museum => {
					return (
						museum.position.lat && (
							<MapMarker
								key={museum.uuid}
								museum={museum}
								isVisible={isVisibleInMap(museum)}
								map={map}
								tooltipFixUpdateForcer={tooltipFixUpdateForcer}
								currentMarkerData={currentMarkerData}
								setCurrentMarkerData={setCurrentMarkerData}
								prevDocumentRef={prevDocumentRef}
								allMarkersRef={allMarkersRef}
							/>
						)
					);

					//console.log(museum);
				})}
		</MarkerClusterGroup>
	);
};

export default React.memo(MapMarkerClusterGroup);
