import CircularProgress from "@material-ui/core/CircularProgress";

import * as R from "ramda";
import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo,
} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import SearchBar from "../components/SearchBar/searchBar";
import Map from "../components/Map/map";
import List from "../components/List/list";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Store } from "../Store";
import { KnService } from "../api/KulturNav/KnService";
import {
	filterByCategoriesAndCounties,
	isVisibleInList,
	sortItemsRecursiveByKeys,
} from "../helpers/filterHelpers";
import { getMuseumsInRange } from "../helpers/getNearestHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Toggler from "../components/Toggler/toggler";
import MapClickBubble from "../components/MapClickBubble/mapClickBubble";
import OpenMap from "../components/OpenMap/openMap";

const smallScreenWidth = 860;

const Container = styled.div`
	height: calc(${props => props.screenHeight + "px"} - 124px); //85 + 39
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto 48px;
	grid-template-areas:
		"listmap"
		"toggler";
	//display: flex;
	//background-color: yellow;
	//border: 2px solid pink;
	//height: 100%;//calc(100% - calc(85px + 39px));
	width: 100vw;

	/* need this for android as 100vh does not work in all cases*/

	//height: calc(100vh - 124px); //85 + 39

	& #map-container > div:last-child {
		position: relative;
		flex: 1;
	}

	@media only screen and (min-width: ${smallScreenWidth + "px"}) {
		grid-template-columns: minmax(400px, 35%) auto;
		grid-template-areas: "list map";
		grid-template-rows: auto;
	}

	@media only screen and (min-width: ${880 + "px"}) {
		height: calc(${props => props.screenHeight + "px"} - calc(85px));
	}
`;

const ListContainer = styled.div`
	grid-area: listmap;
	display: ${props => (props.isMapActive ? "none" : "flex")};
	overflow-y: hidden;
	@media only screen and (min-width: ${smallScreenWidth + "px"}) {
		grid-area: list;
		display: flex;
	}
`;

const MapContainer = styled.div`
	grid-area: listmap;
	display: ${props => (props.isMapActive ? "flex" : "none")};
	min-height: 0;
	justify-content: "center";
	align-items: "center";
	position: relative;

	@media only screen and (min-width: ${smallScreenWidth + "px"}) {
		grid-area: map;
		display: flex;
		//border-radius: 50%;
		margin-right: 22px;
	}
`;

const SearchBarContainer = styled.div`
	display: flex;
	position: absolute;
	top: 8px;
	left: 8px;
	right: 8px;
	z-index: 1000;

	@media only screen and (min-width: ${smallScreenWidth + "px"}) {
		left: 15px;
		margin-right: 15px;
		right: auto;
		top: 15px;
		min-width: 405px;
	}
`;

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TogglerContainer = styled.div`
	grid-area: toggler;
	display: flex;
	@media only screen and (min-width: ${smallScreenWidth + "px"}) {
		display: none;
	}
`;

const MapClickBubbleContainer = styled.div`
	position: fixed;
	display: flex;
	top: ${props => props.yPos + 80}px;
	left: ${props => props.xPos - 15}px;
	z-index: 1;
`;

const getMuseumsInsideBounds = (museums, bounds) => {
	if (museums && bounds) {
		const museumsWithLocation = museums.filter(m => m.position.lat);
		const filtered = museumsWithLocation.filter(m => {
			const lat = m.position.lat;
			const lng = m.position.lng;
			const cor = {
				lat,
				lng,
			};
			return bounds.contains(cor);
		});
		return filtered;
	}
	return museums;
};

const knService = new KnService();

const setVisibilityBoth = (_museum, isVisible) => {
	_museum.isVisibleInList = isVisible;
	_museum.isVisibleInMap = isVisible;
};

const SearchPage = ({}) => {
	const history = useHistory();
	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation("common");
	const { width, height } = useWindowDimensions();
	const [data, setData] = useState([]);
	const [activeMarker, setActiveMarker] = useState({});
	const [isMapActive, setMapActive] = useState(true);
	const [open, setOpen] = useState(false);
	const [bounds, setBounds] = useState();
	const [isSearching, setIsSearching] = useState(false);
	const xhrRef = useRef();
	const [searchOnMove, setSearchOnMove] = useState(false);
	const [bubblePos, setBubblePos] = useState({ x: 0, y: 0 });
	const setBubblePosRef = useRef(setBubblePos);
	const [isSorted, setIsSorted] = useState(false);
	const sortedListRef = useRef(null);
	const [resetMemoizedMuseums, setResetMemoizedMuseums] = useState(false);
	const [listMuseums, setListMuseums] = useState(state.data.museumsList);
	const allMarkersRef = useRef({});
	//const [map, setMap] = useState();
	const mapRef = useRef();

	const [updaterVar, setUpdaterVar] = useState(false);

	const isWide = width > smallScreenWidth;
	/*const classes = useStyles({
	isWide,
	data: !R.isNil(data),
	activeMarker: !R.isEmpty(activeMarker),
  });*/

	const setCurrentPage = () => {
		//console.log("state.user.currentPage: ", state.user.currentPage);
		if (state.user.currentPage === "/museums") {
			return;
		}
		dispatch({
			type: "SET_USER_CURRENTPAGE",
			payload: "/museums",
		});
	};
	setCurrentPage();

	const query = new URLSearchParams(R.path(["location", "search"], history));
	const nearby = () => {
		return { lat: query.get("lat"), lng: query.get("lng") };
	};
	const searchQuery = query.get("query");
	const [searchTerm, setSearchTerm] = useState(searchQuery);
	const setSearchTermRef = useRef(setSearchTerm);

	const search = useCallback(query => {
		setSearchTerm(query);
	}, []);

	const debugMuseumsList = () => {
		console.log("debugMuseumsList()");
		console.log(
			state.data.museumsList.filter(_museum => isVisibleInList(_museum)),
		);
	};
	debugMuseumsList();
	useEffect(() => {
		//console.group("SEARCH USEEFFECT");
		console.log("searchTerm: ", searchTerm);
		let _tmpMuseumsList = filterByCategoriesAndCounties(
			state.data.museumsList,
			state.data.categoriesList,
			state.settings.filtersCategories,
			state.settings.filtersCounties,
		);
		console.log("_tmpMuseumsList");
		console.log(_tmpMuseumsList);

		// remove museums without position
		_tmpMuseumsList = _tmpMuseumsList.filter(_museum => {
			const hasPosition = !!(_museum.position.lat && _museum.position.lng);
			setVisibilityBoth(_museum, hasPosition);

			return hasPosition;
		});
		if (searchTerm) {
			//const knService = new KnService();
			knService
				.search(searchTerm, state.settings.locale)
				.then(_searchResult => {
					if (!_searchResult) {
						console.warn("Can not get data by search");
						throw new Error("Unable to perform knService search");
					}
					_tmpMuseumsList = _tmpMuseumsList.filter(_museum => {
						const foundMuseumUUidInSearchResults = !!_searchResult.find(
							srUuid => srUuid === _museum.uuid,
						);
						if (foundMuseumUUidInSearchResults) {
							console.log(
								_museum.name["no"],
								" :",
								foundMuseumUUidInSearchResults,
							);
							console.log(_museum);
						}
						_museum.isDisabledBySearch = !foundMuseumUUidInSearchResults;
						//setVisibilityBoth(_museum, foundMuseumUUidInSearchResults);
						return foundMuseumUUidInSearchResults;
					});
					console.log("_tmpMuseumsList after search");
					console.log(_tmpMuseumsList);
				})
				.then(() => {
					debugMuseumsList();
					//console.groupEnd("SEARCH USEEFFECT");
					console.count("Museumslist updated");
					setUpdaterVar(val => val + 1);
				});
		}
	}, [state, searchTerm]);

	return (
		<Container screenHeight={height}>
			<Helmet>
				<title>Museer - Museumsguiden.no</title>
			</Helmet>
			{state.data.museumsList.length === 0 ? (
				<LoaderContainer>
					<CircularProgress />
				</LoaderContainer>
			) : (
				<>
					<ListContainer isMapActive={isMapActive}>
						<List
							museums={state.data.museumsList}
							listMuseums={listMuseums}
							setListMuseums={setListMuseums}
							searchTerm={searchTerm}
							active={!isMapActive}
							initialSearch={searchQuery}
							setSearchTerm={setSearchTerm}
							searchOnMove={searchOnMove}
							setSearchOnMove={setSearchOnMove}
							search={search}
							isSearching={isSearching}
							searchSubmitHandler={search}
							setResetMemoizedMuseums={setResetMemoizedMuseums}
							mapRef={mapRef}
							allMarkersRef={allMarkersRef}
						/>
					</ListContainer>

					<MapContainer isMapActive={isMapActive}>
						<SearchBarContainer>
							<SearchBar
								semiTransparent={true}
								sb_variant="medium"
								onMapPage={true}
								searchSubmitHandler={search}
								searchOnMove={searchOnMove}
								setSearchOnMove={setSearchOnMove}
								showHits={true}
								data={state.data.museumsList.filter(_museum =>
									isVisibleInList(_museum),
								)}
								searchTermProp={searchTerm}
							></SearchBar>
						</SearchBarContainer>

						{
							/*midlertidig deaktivert*/ false && !R.isEmpty(activeMarker) && (
								<>
									{console.log("activeMarker:", activeMarker)}
									{console.dir("activeMarker:", activeMarker.marker)}
									{console.log(
										document.getElementById(`marker_${activeMarker.uuid}`),
									)}
									{console.log({
										x: bubblePos.x,
										y: bubblePos.y,
									})}
									<MapClickBubbleContainer
										xPos={bubblePos.x}
										yPos={bubblePos.y}
									>
										<MapClickBubble
											currentMuseum={activeMarker}
										></MapClickBubble>
									</MapClickBubbleContainer>
								</>
							)
						}

						<OpenMap
							mapRef={mapRef}
							//setMap={setMap}
							bounds={bounds}
							setBounds={setBounds}
							updaterVar={updaterVar}
							allMarkersRef={allMarkersRef}
							//setUpdaterVar={setUpdaterVar}
						/>
					</MapContainer>

					<TogglerContainer>
						<Toggler
							active={!isWide}
							isMapActive={isMapActive}
							onPress={setMapActive}
							activeMarker={!R.isEmpty(activeMarker)}
						/>
					</TogglerContainer>
				</>
			)}
		</Container>
	);
};

export default React.memo(SearchPage);
