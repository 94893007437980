import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Typography,
	FormGroup,
	Checkbox,
	FormControlLabel,
	Button,
} from "@material-ui/core";
import { Store } from "../../Store";
import {
	CheckBoxOutlined,
	CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { updateCheckboxes } from "../../helpers/filterHelpers";
import { translate } from "../../hooks/useFallbackTrans";
import styled from "styled-components";
import { text_base } from "../../style/styles";

/* TODO: Merge into single component for both counties and categories */

const useStyles = makeStyles({
	buttonRow: {
		display: "flex",
		marginLeft: "-12px", // compensate for padding
	},
	toggleFilterButton: {
		marginLeft: "8px",
		fontSize: "14px",
		textTransform: "none",
		fontWeight: "400",
		whiteSpace: "nowrap",
	},

	toggleFilterIcon: {
		width: "24px",
		height: "24px",
		marginRight: "-3px",
	},
	countiesLabelRoot: {
		marginLeft: "0px",
	},
	countiesLabel: {
		display: "block",
		fontSize: "14px",
		lineHeight: "19px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "70vw",
	},
	countiesCheckbox: {
		padding: "5px 5px 5px 0px",
	},
	horizontalLine: {
		display: "block",
		width: "100%",
		height: "2px",
		backgroundColor: "#EDEDED",
		margin: "5px 0px",
	},

	applyButton: {
		textTransform: "none",
	},

	helpText: {
		fontSize: "12px",
		fontWeight: "300",
	},
});

const CountiesLabel = styled(Typography)`
	display: block;
	${text_base}
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 70vw;
	user-select: none;
`;

let categoryTimer;

const ListCounties = ({ isWide, timeOut = 2000 }) => {
	const { state, dispatch } = useContext(Store);
	const classes = useStyles({ isWide });
	const [checkerState, setCheckerState] = useState({});
	const [counties, setCounties] = useState([]);
	const stateRef = React.useRef(state);
	const dispatchRef = React.useRef(dispatch);
	const { t } = useTranslation("common");
	const hasChangedRef = React.useRef({ hasChanged: false });

	const handleChange = e => {
		hasChangedRef.current.hasChanged = true;
		setCheckerState({ ...checkerState, [e.target.name]: e.target.checked });
	};

	const changeAllCheckers = bool => {
		const tmp_checkerState = { ...checkerState };
		Object.keys(tmp_checkerState).forEach(el => (tmp_checkerState[el] = bool));
		hasChangedRef.current.hasChanged = true;
		setCheckerState(tmp_checkerState);
	};

	const selectAll = () => changeAllCheckers(true);
	const selectNone = () => changeAllCheckers(false);

	const filterMuseumsByCounty = checkedCounties => {
		const filteredCounties = checkedCounties.filter(county => county);

		dispatchRef.current({
			type: "SET_SETTINGS_FILTERSCOUNTIES",
			payload: filteredCounties,
		});
	};

	const applyFilter = () => {
		const checkerState_tmp = checkerState;
		const checkedCounties = Object.entries(checkerState_tmp).map(county => {
			return county[1] ? county[0] : null;
		});
		filterMuseumsByCounty(checkedCounties);
	};

	useEffect(() => {
		const filterMuseumsByCounty = () => {
			const filteredCounties = checkedCounties.filter(county => county);

			dispatchRef.current({
				type: "SET_SETTINGS_FILTERSCOUNTIES",
				payload: filteredCounties,
			});
		};

		//console.log("stateRef: ", stateRef.current);
		const filterState = state.settings.filtersCounties;
		const hasChanged = hasChangedRef.current.hasChanged;

		if (!hasChanged) {
			return;
		}
		hasChangedRef.current.hasChanged = false;

		const checkedCounties = Object.entries(checkerState).map(county => {
			return county[1] ? county[0] : null;
		});
		const filteredCounties = checkedCounties.filter(county => county);

		clearTimeout(categoryTimer);
		categoryTimer = setTimeout(filterMuseumsByCounty, timeOut);

		return () => clearTimeout(categoryTimer);
	}, [checkerState]);

	// fetches and sorts counties
	useEffect(() => {
		if (!state.data.countiesList) {
			return;
		}
		const countiesList = state.data.countiesList;
		const countiesTmp = Object.values(countiesList);
		countiesTmp.sort((a, b) => {
			const aValue = translate(a.name, stateRef.current.settings.locale);
			const bValue = translate(b.name, stateRef.current.settings.locale);
			if (aValue < bValue) return -1;
			if (aValue > bValue) return 1;
			return 0;
		});
		setCounties(countiesTmp);

		const filterState = state.settings.filtersCounties;
		const checkerState_tmp = updateCheckboxes(
			countiesList,
			filterState,
			"uuid",
		);
		setCheckerState(checkerState_tmp);
	}, [state.data.countiesList]);

	// update checkboxes on state change
	useEffect(() => {
		if (!checkerState) {
			return;
		}
		const countiesList = state.data.countiesList;
		const filterState = state.settings.filtersCounties;
		const checkerState_tmp = updateCheckboxes(
			countiesList,
			filterState,
			"uuid",
		);
		setCheckerState(checkerState_tmp);
	}, [state.settings.filtersCounties]);

	/*
        const debugInfo = () => {
            console.group("FilterCounties");
            console.log("counties: ",counties);
            console.log("checkerState: ", checkerState);
            console.log("state: ", state);
            console.groupEnd();
        }
    */

	return (
		<div className={classes.countiesContainer}>
			<div className={classes.buttonRow}>
				{Object.values(checkerState).every(el => el === true) ? (
					<Button
						className={classes.toggleFilterButton}
						onClick={selectNone}
						startIcon={
							<CheckBoxOutlined className={classes.toggleFilterIcon} />
						}
					>{`${t("filterText.select")} ${t("filterText.none")}`}</Button>
				) : (
					<Button
						className={classes.toggleFilterButton}
						onClick={selectAll}
						startIcon={
							<CheckBoxOutlineBlankOutlined
								className={classes.toggleFilterIcon}
							/>
						}
					>{`${t("filterText.select")} ${t("filterText.all")}`}</Button>
				)}
			</div>
			<div className={classes.horizontalLine}></div>

			<FormGroup>
				{Object.values(counties).map(currentCounty => (
					<FormControlLabel
						className={classes.countiesLabelRoot}
						key={currentCounty.uuid}
						control={
							<Checkbox
								className={classes.countiesCheckbox}
								checked={
									checkerState[currentCounty.uuid] === undefined
										? false
										: checkerState[currentCounty.uuid]
								}
								name={currentCounty.uuid}
								onChange={handleChange}
							></Checkbox>
						}
						label={
							<CountiesLabel>
								{translate(currentCounty.name, state.settings.locale)}
							</CountiesLabel>
						}
					></FormControlLabel>
				))}
				<div className={classes.horizontalLine}></div>
				{/*<Button className={classes.applyButton} onClick={applyFilter}>{t("general.apply")}</Button>*/}
				{/*<Typography className={classes.helpText}>{t("filterText.instructionsCounties")}</Typography>*/}
			</FormGroup>
		</div>
	);
};

export default ListCounties;
