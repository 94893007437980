import { css } from "styled-components";

/**
 * FONT STYLES:
 */

// NORMAL

export const text_xs = css`
	/* text-xs */
	font-family: var(--akkurat);
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0em;
`;

export const text_sm = css`
	font-family: var(--akkurat);
	//styleName: text-sm;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;
`;

export const text_base = css`
	//styleName: text-base;
	font-family: var(--akkurat);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
`;
export const text_lg = css`
	//styleName: text-lg;
	font-family: var(--akkurat);
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;
`;

export const text_xl = css`
	//styleName: text-xl;
	font-family: var(--akkurat);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;
`;

export const text_2xl = css`
	//styleName: text-2xl;
	font-family: var(--akkurat);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 0em;
`;

//BOLD

export const text_xs_bold = css`
	font-family: var(--akkurat);
	//styleName: text-xs--bold;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0em;
`;

export const text_sm_bold = css`
	font-family: var(--akkurat);
	//styleName: text-sm--bold;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0em;
`;

export const text_base_bold = css`
	//styleName: text-base--bold;
	font-family: var(--akkurat);
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
`;
export const text_lg_bold = css`
	font-family: var(--akkurat);
	//styleName: text-lg--bold;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0em;
`;

export const text_xl_bold = css`
	font-family: var(--akkurat);
	//styleName: text-xl--bold;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0em;
`;

export const text_2xl_bold = css`
	font-family: var(--akkurat);
	//styleName: text-2xl--bold;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0em;
`;

export const text_3xl_bold = css`
	font-family: var(--akkurat);
	//styleName: text-3xl--bold;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px;
	letter-spacing: 0em;
`;

export const text_4xl_bold = css`
	font-family: var(--akkurat);
	//styleName: text-4xl--bold;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: 40px;
	letter-spacing: 0em;
`;

export const text_5xl_bold = css`
	/* text-5xl--bold */
	font-family: var(--akkurat);
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 48px;
	letter-spacing: 0em;
`;

/*
export const text_lg_bold = css`
    font-family: var(--akkurat); 
    
`

*/
