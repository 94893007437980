import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Link from "@material-ui/core/Link";
import RemoveIcon from "@material-ui/icons/Remove";
import List from "@material-ui/core/List";
import React from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Settings } from "../../config/Settings";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import { ReactComponent as LogoMG } from "../../assets/images/header_icons/mg-logo-med-symbol.svg";

//const drawerWidth = 240;
const smallWidth = 650;

/* TODO:
  - set max-height on footer to prevent it from filling up the whole screen on mobile in landscape mode.
  - add extra css breakpoint for middle sized screens.
  - add hover and selection styles to footer links.


*/

const useStyles = makeStyles({
	root: {
		display: "flex",
		overflowY: "auto",
	},
	hide: {
		display: "none",
	},
	appBar: {
		backgroundColor: "var(--Museumssvart)",
		width: "100%",
	},
	footer: {
		"--footerFontSize": props => (props.isWide && "18px") || "14px",
		"--footerHeaderSize": props => (props.isWide && "24px") || "14px",
		paddingTop: props => (props.isWide && 53) || 30,
		paddingBottom: props => (props.isWide ? 30 : 100),
		display: "flex",
		flex: 1,
		paddingLeft: props => (props.isWide && 62) || 30,
		paddingRight: 20,
		flexDirection: props => (props.isWide && "row") || "column",
	},
	footerTitle: {
		color: "var(--gray500)",
		fontSize: "var(--footerHeaderSize)",
		fontFamily: "var(--akkurat)",
		fontWeight: 700,
		marginBottom: "0.5em",
		"&::first-letter": {
			textTransform: "uppercase",
		},
	},

	mirroredText: {
		transform: "scaleX(-1)",
		display: "inline-block",
	},

	titleRow: {
		display: "flex",
		alignItems: "center",
	},

	footerLink: {
		textDecoration: "none",
		color: "var(--gray500)",
		fontWeight: "700",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	description: {
		color: "var(--gray500)",
		fontFamily: "var(--akkurat)",
		fontSize: "var(--footerFontSize)",
		fontWeight: 300,
	},
	menu: {
		display: "flex",
		//flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		marginLeft: props => props.isWide && 80,
		//width: "100%",
		flexWrap: "wrap",
		//listStyle: "square",
		//listStyleType: "-",
	},
	menuItemText: {
		color: "var(--gray500)",
		fontSize: "var(--footerFontSize)",
		fontWeight: 700,
		cursor: "pointer",
		flex: "0 1 auto",
	},
	menuItem: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: 0,
		width: "fit-content",
		display: "flex",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	listItemIconContainer: {
		display: "none",
		minWidth: 30,
	},
	listItemIcon: {
		color: "#FFF",
		width: 23,
	},
	aboutText: {
		maxWidth: 1000,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	version: {
		color: "var(--gray500)",
		fontSize: "14px",
		fontWeight: "300",
		fontStyle: "italic",
		marginLeft: "5px",
	},

	listIcon: {
		color: "var(--gray500)",
		height: props => (props.isWide ? "14px" : "10px"),
	},
});

const FooterLogoContainer = styled.div`
	display: flex;
	//background-color: white;
`;

const FooterLogo = styled(LogoMG)`
	height: 30px;

	//.cls-1{fill:#1c2225}.cls-3{fill:none;stroke:#1c2225
	.cls-1 {
		fill: var(--gray500);
	}
	.cls-2 {
		stroke: var(--gray500);
	}
	.cls-3 {
		stroke: var(--gray500);
	}
`;

const Footer = () => {
	const { t } = useTranslation("common");
	const { width } = useWindowDimensions();
	const history = useHistory();
	const classes = useStyles({ isWide: width > smallWidth });

	return (
		<div className={classes.appBar}>
			<div className={classes.footer}>
				<div className={classes.aboutText}>
					<div className={classes.titleRow}>
						<FooterLogoContainer>
							<FooterLogo />
						</FooterLogoContainer>
						<Typography className={classes.version} variant={"body2"}>
							{`v ${Settings.APP_VERSION}`}
						</Typography>
					</div>
					<Typography variant="h6" className={classes.description}>
						{t("general.footerText")}
						<a className={classes.footerLink} href={`${Settings.URL_KULTURIT}`}>
							{" "}
							KulturIT
						</a>
						.
					</Typography>
				</div>
				<List className={classes.menu}>
					{[
						{
							text: t("footer.readMoreAboutKulturit"),
							url: "https://kulturit.org/om-kulturit",
						},
						{
							text: t("footer.privacyInfo"),
							url: "https://kulturit.org/personvern",
						},
					].map(link_obj => (
						<ListItem button key={link_obj.text} className={classes.menuItem}>
							<ListItemText
								primary={
									<Link
										className={classes.menuItemText}
										href={link_obj.url}
										target="_blank"
									>
										{link_obj.text}
									</Link>
								}
								className={classes.menuItemText}
							/>
							<ListItemIcon>
								<ArrowForwardIosIcon className={classes.listIcon} />
							</ListItemIcon>
						</ListItem>
					))}
					<ListItem
						onClick={() => {
							history.push("/list");
						}}
						className={classes.menuItem}
						data-testid="listbtn"
					>
						<ListItemText
							primary={
								<Link className={classes.menuItemText}>
									{t("footer.listLink")}
								</Link>
							}
						/>
						<ListItemIcon>
							<ArrowForwardIosIcon className={classes.listIcon} />
						</ListItemIcon>
					</ListItem>
				</List>
			</div>
		</div>
	);
};

export default React.memo(Footer);
