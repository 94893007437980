import i18next from "i18next";
import * as R from "ramda";

import common_en from "./locales/en/common.json";
import common_no from "./locales/no/common.json";

const initLng = R.propOr("no", "language", navigator).substring(0, 2);

i18next.init({
	interpolation: { escapeValue: false },
	lng: initLng,
	resources: {
		en: {
			common: common_en,
		},
		no: {
			common: common_no,
		},
	},
	fallbackLng: {
		nb: ["no"],
		nn: ["no"],
		sv: ["no"],
		se: ["no"],
		default: ["en"],
	},
});

export default i18next;
