import React, { useContext, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import ListItem from "../ListItem/listitem";
import { CircularProgress } from "@material-ui/core";
import { FormControlLabel, Radio } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { sortItemsRecursiveByKeys } from "../../helpers/filterHelpers";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const ItemsContainer = styled.div`
	-webkit-tap-highlight-color: transparent;
	user-select: none;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	list-style-type: none;
	overflow-y: auto;
`;

const LoadContainer = styled.div`
	padding: 100px;
	display: flex;
	justify-content: center;
	align-content: center;
`;

const CheckboxContainer = styled.div`
	padding: 10px;
	display: flex;
	justify-content: space-evenly;
	background: #fff;
	border-bottom: 1px solid #ccc;
`;

const List = ({ items = [] }) => {
	const { t } = useTranslation("common");
	const [sortBy, setSortBy] = useState("name");
	const memoizedItems = useMemo(() => {
		let keys = sortBy === "name" ? ["name"] : ["distance"];
		return sortItemsRecursiveByKeys(items, keys);
	}, [sortBy, items]);
	return (
		<Container>
			{items.length > 0 && !!items[1].distance && (
				<CheckboxContainer>
					<FormControlLabel
						checked={sortBy === "name"}
						control={<Radio />}
						label={t("general.filterByName")}
						onClick={() => setSortBy("name")}
					/>
					<FormControlLabel
						checked={sortBy === "distance"}
						control={<Radio />}
						label={t("general.filterByDistance")}
						onClick={() => setSortBy("distance")}
					/>
				</CheckboxContainer>
			)}
			<ItemsContainer>
				{memoizedItems.length > 0 ? (
					memoizedItems.map(item => <ListItem key={item.uuid} {...item} />)
				) : (
					<LoadContainer>
						<CircularProgress />
					</LoadContainer>
				)}
			</ItemsContainer>
		</Container>
	);
};

export default React.memo(List, (a, b) => {
	return a.items === b.items;
});
