import { Api } from "./Api";

export class BeService extends Api {
	LIST_UUID = "eb04bd82-30cd-4f7c-9f71-51f009c3270b";

	getMuseumsList = async () => {
		const musList = await this.get(`/museums`);
		//console.log("\n\n\n\nmusList:",musList);
		const result = musList || []; //JSON.parse(this.musList);
		//console.log(result);
		return result;
	};

	getCategoriesList = async () => {
		const catList = await this.get(`/categories`);
		//console.log("\n\n\n\ncatList:",catList);
		const result = catList || {}; //JSON.parse(this.musList);
		//console.log(result);
		return result;
	};

	getCountiesList = async () => {
		const countiesList = await this.get(`/counties`);
		//console.log("\n\n\n\ncatList:",countiesList);
		const result = countiesList || []; //JSON.parse(this.musList);
		//console.log(result);
		return result;
	};
}
