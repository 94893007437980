import { Button } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { text_base_bold } from "../../style/styles";

const Container = styled.div`
	position: relative;
	background-color: #fff;
	padding: 12px 13px 14px 13px;
	border-radius: 12px;
	color: #000;
	box-shadow: 0px 18px 58px 0px rgba(0, 0, 0, 0.25);
`;

const Triangle = styled.div`
	position: absolute;
	background-color: #fff;
	width: 9px;
	height: 9px;
	transform: rotate(45deg);
	top: -4.5px;
	left: 23%;
`;

const BubbleButton = styled(Button)`
	${text_base_bold}
	text-transform: none;
`;

const ArrowIconCustom = styled(ArrowForwardIos)`
	color: var(--blaze500);
`;

const MapClickBubble = ({ currentMuseum }) => {
	const history = useHistory();

	// Navigate to details screen
	const navigateTo = (event, data) => {
		// Skip nested link clicks
		if (event.target) {
			const dataType = event.target.getAttribute("data-type");

			if (dataType === "part-of-link") {
				return;
			}
		}

		history.push({
			pathname: `/museums/${data.uuid}`,
			state: { data },
		});
	};
	return (
		<Container>
			<BubbleButton
				onClick={() => {
					history.push({ pathname: `/museums/${currentMuseum.uuid}` });
				}}
				endIcon={<ArrowIconCustom />}
			>
				Se mer
			</BubbleButton>
			<Triangle />
		</Container>
	);
};

export default MapClickBubble;
