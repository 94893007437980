import { Button, makeStyles } from "@material-ui/core";
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useFallbackTrans from "../../hooks/useFallbackTrans";

const useStyles = makeStyles(() => ({
	readMoreContainer: {
		position: "absolute",
		bottom: 0,
		alignSelf: "flex-end",
		justifySelf: props => (props.isWide ? "flex-end" : "center"),
		justifyContent: "center",
		alignItems: "center",

		//paddingRight: props => props.isWide ? "60px": "15px",
		//paddingBottom: "21px",
		padding: "18px 32px",
		background: "rgba(50, 30, 22, 0.4)",
		backdropFilter: "blur(5px)",
		borderRadius: "16px 0px 0px 0px",
	},

	readMoreButton: {
		display: "flex",
		fontFamily: "var(--akkurat)",
		color: "var(--gray400)",
		fontWeight: 400,
		textTransform: "none",
		fontSize: props => (props.isWide && "14px") || "14px",
		lineHeight: "20px",
		flexWrap: "wrap",
		"&:hover": {
			textShadow: "0px 0px 1px var(--gray400)",
		},
		//whiteSpace: "nowrap",
	},
	readMore: {
		whiteSpace: "nowrap",
	},
	readMoreLink: {
		textDecoration: "underline",
		marginLeft: "1ch",
	},
}));

const ReadMoreButton = ({ bgImageMuseum, isWide }) => {
	const history = useHistory();
	const { t } = useTranslation("common");
	const classes = useStyles({ isWide: isWide });
	const museumName = useFallbackTrans(bgImageMuseum.name);

	return (
		<div className={classes.readMoreContainer}>
			<Button
				data-testid={"readmore_btn"}
				className={classes.readMoreButton}
				onClick={() => {
					bgImageMuseum.uuid &&
						history.push({ pathname: `/museums/${bgImageMuseum.uuid}` });
				}}
			>
				<span className={classes.readMore}>{t("general.imageFrom")}</span>
				<span className={classes.readMoreLink}>
					{museumName ? museumName : "loading ..."}
				</span>
			</Button>
		</div>
	);
};

export default React.memo(ReadMoreButton);
