import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import { renderToString } from "react-dom/server";
//import MarkerClusterGroup from "react-leaflet-cluster";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	Tooltip,
	useMapEvents,
	ZoomControl,
	latLngBounds,
	useMap,
} from "react-leaflet";
import * as L from "leaflet";
import { Store } from "../../Store";
import MapMarkerClusterGroup from "./mapMarkerClusterGroup";
import { boundsExtender } from "./helpers/boundsHelpers";
import markerIcon from "../../assets/images/markerIcon.png";
import markerIconOutline from "../../assets/images/markerIconOutline.png";
import { isVisibleInMap } from "../../helpers/filterHelpers";

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const MapContainerCustom = styled(MapContainer)`
	height: 100%;
	width: 100%;

	.leaflet-tile-pane {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
	.leaflet-tooltip {
		//color: red;
		background-color: rgba(255, 255, 255, 0.85);
		pointer-events: auto;
		&:hover {
			z-index: 100;
			//z-index: 30000;
		}
	}

	.leaflet-marker-hidden {
		display: none;
		opacity: 0;
	}
	//.leaflet-marker-icon.leaflet-interactive:hover
	.leaflet-marker-icon:hover {
		filter: drop-shadow(10px 10px 15px black);
	}
	/*
	.leaflet-tooltip-hover {
		color: green;
		//z-index: 30000;
	}
	.leaflet-tooltip-normal {
		color: black;
		//z-index: 30000;
	}*/

	.hidden-marker {
		display: none;
	}

	@media only screen and (min-width: 800px) {
		border-radius: 24px;
	}
`;
var TOOLTIP_POSITION_FIX_TIMEOUT = null;

function LocationMarker() {
	const [position, setPosition] = useState(null);
	const map = useMapEvents({
		click() {
			map.locate();
		},
		locationfound(e) {
			setPosition(e.latlng);
			map.flyTo(e.latlng, map.getZoom());
		},
	});

	return position === null ? null : (
		<Marker position={position}>
			<Popup>You are here</Popup>
		</Marker>
	);
}

const DebugMapElement = () => {
	const map = useMapEvents({
		click() {
			console.log(map.getBounds());
		},
	});
	return <Marker position={[0, 0]} />;
};

const OpenMap = ({ mapRef, updaterVar, bounds, setBounds, allMarkersRef }) => {
	const { state } = useContext(Store);
	//const [bounds, setBounds] = useState();
	const [map, setMap] = useState();
	const [tooltipFixUpdateForcer, setTooltipFixUpdateForcer] = useState(0); // Hack for fixing a bug causing tooltips to not update its position.
	const [mapMuseumsList, setMapMuseumsList] = useState([]);
	const testRef = useRef(undefined);
	const selectedMuseums = [];
	const limitByBoundsRef = useRef(true);
	//const [limitByBounds, setLimitByBounds] = useState(true);
	const [currentMarkerData, setCurrentMarkerData] = useState({
		setCurrentMarkerIconRef: null,
		currentMuseum: null,
	});
	//const mapRef = useRef(map);

	/*useEffect(() => {
		if (!state.data.museumsList.length) {
			return;
		}
		setMapMuseumsList(state.data.museumsList);
	}, [state.data.museumsList]);*/

	const boundsExtender = () => {
		const _bounds = L.latLngBounds();
		console.log("boundsStart");
		console.log(_bounds);
		//console.countReset("visibleMuseums")
		state.data.museumsList.forEach(museum => {
			if (
				isVisibleInMap(museum) &&
				museum.position.lat &&
				museum.position.lng
			) {
				//console.count("visibleMuseums")
				const latlng = L.latLng({
					lat: museum.position.lat,
					lng: museum.position.lng,
				});
				//console.log(latlng);
				_bounds.extend(latlng);
			}
		});

		if (_bounds.isValid()) {
			setBounds(_bounds);
			console.log("bounds");
			console.log(_bounds);
			map.flyToBounds(_bounds, { padding: [30, 30] }).on("moveend", () => {
				map.off("moveend");
				TOOLTIP_POSITION_FIX_TIMEOUT = setTimeout(() => {
					console.log("TIMEOUT TRIGGERED");
					setTooltipFixUpdateForcer(prev => prev + 1);
				}, 500);
			});
			// This timeout seems to fix a bug causing tooltips at some occations to not update positions after fitting bounds.

			/*TOOLTIP_POSITION_FIX_TIMEOUT = setTimeout(() => {
				console.log("TIMEOUT TRIGGERED");
				setTooltipFixUpdateForcer(prev => prev + 1);
			}, 1000);*/
		} else {
			console.warn("Invalid bounds!, ", _bounds);
		}
		//setLimitByBounds(false);
		limitByBoundsRef.current = false;
	};

	map && limitByBoundsRef.current === true && boundsExtender();

	useEffect(() => {
		if (!map) return;
		console.log("\n\n\nFILTERS UPDATED\n\n\n\n");
		//boundsExtender();
		console.log("map object");
		console.dir(map);
		//setLimitByBounds(true);
		//setLimitByBounds(true);
		limitByBoundsRef.current = true;
	}, [
		state.settings.filtersCounties,
		state.settings.filtersCategories,
		//updaterVar,
	]);

	// Cleanup
	useEffect(() => {
		return () => {
			clearTimeout(TOOLTIP_POSITION_FIX_TIMEOUT);
		};
	}, []);
	return (
		<Container>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://unpkg.com/leaflet/dist/leaflet.css"
				/>

				<link
					rel="stylesheet"
					href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
				/>
			</Helmet>
			<MapContainerCustom
				bounds={bounds}
				center={[59.91457, 10.73614]}
				zoom={13}
				minZoom={3}
				maxZoom={19}
				scrollWheelZoom={true}
				zoomControl={false}
				whenCreated={mapObj => {
					mapRef.current = mapObj;
					setMap(mapObj);
				}}
				//maxBounds={L.latLngBounds(L.latLng(85, 131), L.latLng(24, -84))}
				//maxBoundsViscosity={1.0}
				eventHandlers={{
					click: () => {
						console.log(map.getBounds());
					},
				}}
			>
				<MapMarkerClusterGroup
					museumsList={state.data.museumsList}
					map={map}
					tooltipFixUpdateForcer={tooltipFixUpdateForcer}
					currentMarkerData={currentMarkerData}
					setCurrentMarkerData={setCurrentMarkerData}
					allMarkersRef={allMarkersRef}
				/>
				<TileLayer
					maxZoom={20}
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors&nbsp;&nbsp;&nbsp;&nbsp;'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<ZoomControl position="bottomright" />
				{false && <DebugMapElement />}
			</MapContainerCustom>
		</Container>
	);
};

export default React.memo(OpenMap);
