import React, { useReducer } from "react";

export const Store = React.createContext();

const initialState = {
	user: {
		lng: null,
		lat: null,
		position: "",
		currentPage: "/",
	},
	settings: {
		locale: "no",
		filtersCounties: [],
		filtersCategories: [],
		searchNearby: false,
		listScrolled: false,
	},
	data: {
		museumsList: [],
		brandingList: [],
		categoriesList: {},
		countiesList: [],
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case "SET_USER":
			return { ...state, user: action.payload };
		case "SET_USER_CURRENTPAGE":
			return { ...state, user: { ...state.user, currentPage: action.payload } };
		case "SET_SETTINGS":
			return { ...state, settings: action.payload };
		case "SET_SETTINGS_FILTERSCATEGORIES":
			return {
				...state,
				settings: { ...state.settings, filtersCategories: action.payload },
			};
		case "SET_SETTINGS_FILTERSCOUNTIES":
			return {
				...state,
				settings: { ...state.settings, filtersCounties: action.payload },
			};
		case "SET_SETTINGS_SEARCHNEARBY":
			return {
				...state,
				settings: { ...state.settings, searchNearby: action.payload },
			};
		case "SET_SETTINGS_LISTSCROLLED":
			return {
				...state,
				settings: { ...state.settings, listScrolled: action.payload },
			};
		case "SET_SETTINGS_LOCALE":
			return {
				...state,
				settings: { ...state.settings, locale: action.payload },
			};
		case "SET_DATA":
			return { ...state, data: action.payload };
		case "SET_DATA_MUSEUMSLIST":
			return { ...state, data: { ...state.data, museumsList: action.payload } };
		case "SET_DATA_BRANDINGLIST":
			return {
				...state,
				data: { ...state.data, brandingList: action.payload },
			};
		case "SET_DATA_CATEGORIESLIST":
			return {
				...state,
				data: { ...state.data, categoriesList: action.payload },
			};
		case "SET_DATA_COUNTIESLIST":
			return {
				...state,
				data: { ...state.data, countiesList: action.payload },
			};
		default:
			return state;
	}
};

export const StoreProvider = props => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };
	return <Store.Provider value={value}>{props.children}</Store.Provider>;
};
