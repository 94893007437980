import React, { useEffect, useState } from "react";
import { BeService } from "../api/BackEnd/BeService";
//import {KnService} from "../api/KulturNav/KnService";

//const knService = new KnService();
const beService = new BeService();

const fetchData = async type => {
	switch (type) {
		case "museums":
			return await beService.getMuseumsList();
		case "categories":
			return await beService.getCategoriesList();
		case "counties":
			return await beService.getCountiesList();
		default:
			console.warn(`DataType ${type} does not exist in useList!`);
			return [];
	}
};

const useList = listType => {
	console.count("useList");
	const [list, setList] = useState([]);
	useEffect(() => {
		fetchData(listType).then(data => {
			if (listType === "museums") {
				setList(
					data.map(museum => {
						museum.isVisibleInMap = true;
						museum.isVisibleInList = true;
						return museum;
					}),
				);
			} else {
				setList(data);
			}
		});
	}, [listType]);
	return list;
};

export default useList;
