import React, { useContext, useMemo, useState } from "react";
import { Store } from "../Store";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import List from "../components/List2/list";
import { translate } from "../hooks/useFallbackTrans";

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const ListContainer = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	> div {
		width: 100%;
	}
	@media (min-width: 768px) {
		width: 100%;
		margin: 50px 0;

		> div {
			width: 600px;
		}
	}
`;

const generateList = (list, locale) => {
	if (list < 1) {
		return [];
	}
	const museumsList = [...list];
	const processedMuseums = [];

	const saveEntry = (museumEntry, parentItems) => {
		const arrayLength = parentItems.push({
			uuid: museumEntry.uuid,
			name: translate(museumEntry.name, locale),
			image: museumEntry.image,
			distance: parseInt(museumEntry.distance),
			items: [],
		});
		return parentItems[arrayLength - 1];
	};
	museumsList.forEach(museum => {
		const museumEntry = museumsList.find(entry => entry.uuid === museum.uuid);
		if (museumEntry.isPartOf) {
			return;
		}
		const recursiveParse = (museumEntry, parentItems) => {
			const parentArrayRef = saveEntry(museumEntry, parentItems);
			if (museumEntry.hasParts.length < 1) {
				return;
			}
			museumEntry.hasParts.forEach(museumPart => {
				// find part from museumsList
				const _museumEntry = museumsList.find(
					entry => entry.uuid === museumPart.value,
				);

				// child not found
				if (!_museumEntry) {
					return;
				}
				recursiveParse(_museumEntry, parentArrayRef.items);
			});
		};
		// Top elements are insertet directly into object
		recursiveParse(museumEntry, processedMuseums);
	});
	return processedMuseums;
};

const ListPage = () => {
	const { state } = useContext(Store);
	const { t } = useTranslation("common");
	const [open, setOpen] = useState(false);

	// TODO: handle isPartOf where parent is not in museumsguiden
	const drawerHandler = React.useCallback(() => {
		setOpen(prev => !prev);
	}, []);

	const hierarchyList = useMemo(() => {
		if (
			!(
				(
					state.data.museumsList.length > 0
				) /*&& 'distance' in state.data.museumsList[1]*/
			)
		) {
			return [];
		}
		return generateList(state.data.museumsList, state.settings.locale);
	}, [state.data.museumsList, state.settings.locale]);

	return (
		<Container>
			<Helmet>
				<title>List - Museumsguiden.no</title>
			</Helmet>
			{/*<Header
        title={t('general.appTitle')}
        handleDrawerOpen={drawerHandler}
        openDrawer={open}
      />
      {open && (
        <Drawer
          handleDrawerClose={drawerHandler}
          openDrawer={open}
        />
      )}*/}
			<ListContainer>
				<List items={hierarchyList} />
			</ListContainer>
			{/*<Footer/>*/}
		</Container>
	);
};

export default ListPage;
