import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "../../Store";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SearchParameters from "./searchParameters";
import ReadMoreButton from "../ReadMoreButton/readMoreButton";
import SearchBar from "../SearchBar/searchBar";
import styled from "styled-components";

const MIN_WIDTH = 880;
const MID_WIDTH = 600;

const useStyles = makeStyles(theme => ({
	main: {
		display: "flex",
		position: "relative",
		flex: "1 1",
		justifyContent: props =>
			props.isWide && props.screenHeight > 950 ? "center" : "flex-start",
		alignItems: "center",
		flexDirection: "column",
		backgroundImage: props => `url(${props.bgImage}?dimension=max)`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		boxShadow: "inset 0 0 0 max(100vw, 100vh) #00000060",
		minHeight: props =>
			props.isWide ? "max(85vh, 700px)" : "max(85vh, 600px)",
		width: "100%",
	},

	searchContainer: {
		display: "flex",
		flexDirection: "column",
		//width: props => props.isWide ? "max(33%, 550px)" : "90%",
		paddingTop: "100px", //props.isWide ? "200px" : "100px",
		paddingBottom: "100px",
		width: "80vw",
		alignItems: "center",
		height: "500px",
		//border:  "2px yellow solid" ,
	},

	row: {
		display: "flex",
		alignItems: "center",
		width: "100%",
	},

	buttonSearch: {
		//backgroundColor: '#C0392B',
		color: "#FFF",
		fontFamily: "var(--roboto)",
		fontWeight: 700,
		fontSize: props => (props.isWide && "20px") || "14px",
		textTransform: "uppercase",
		marginLeft: 0,
		transform: "translateX(-10px)",
		height: props => (props.isWide && 60) || 40,
		"--sidePadding": "1.4em",
		paddingLeft: "var(--sidePadding)",
		paddingRight: "var(--sidePadding)",
		justifyContent: "center",
		alignItems: "center",
	},

	textInput: {
		width: "100%",
	},

	textInputRoot: {
		height: props => (props.isWide && 60) || 40,
		backgroundColor: "#fff",
		boxShadow: "0px 0px 10px #00000080",
	},

	notchedOutline: {
		border: "none",
	},

	searchParameters: {
		display: "flex",
	},
}));

const SearchBarSmallContainer = styled.div`
	display: flex;
	width: 100%;
	@media only screen and (min-width: ${MIN_WIDTH + "px"}) {
		display: none;
	}
`;

/*const SearchBarMediumContainer = styled.div`  
  display: none;
  @media only screen and (min-width: ${MID_WIDTH+"px"}){
    display: flex;
  }
`;
*/

const SearchBarBigContainer = styled.div`
	display: none;
	width: 100%;
	max-width: 950px;
	@media only screen and (min-width: ${MIN_WIDTH + "px"}) {
		display: flex;
	}
`;

// Get image and museumsdata from random museum
const getBgRandomMusData = list => {
	const haveImage = item => {
		return (
			item &&
			"image" in item &&
			item.image &&
			typeof item.image === "string" &&
			item.image.includes("http")
		);
	};
	if (!(list && list.length > 0)) {
		return { image: "", id: "", name: "" };
	}
	const MAX_ATTEMPTS = 100;
	let attempts = 0;
	let _item = null;
	while (!haveImage(_item) && attempts < MAX_ATTEMPTS) {
		let randomListIndex = Math.floor(Math.random() * list.length);
		_item = list[randomListIndex];
		attempts++;
	}
	return {
		name: _item.name,
		id: _item.id,
		image: _item.image,
		uuid: _item.uuid,
	};
};

// Get image and museumsdata from nearest museum
const getBgNearestMusData = list => {
	if (list.length < 1 || !list[0].distance) {
		return { image: "", id: "", name: "" };
	}
	let _nearestMuseum = null;
	for (let _museum of list) {
		if (!_museum.distance || !_museum.image) continue; // in case of missing position or image

		if (_nearestMuseum === null) {
			_nearestMuseum = _museum;
			continue;
		}
		if (_nearestMuseum.distance > _museum.distance) {
			_nearestMuseum = _museum;
		}
	}
	return {
		name: _nearestMuseum.name,
		id: _nearestMuseum.id,
		image: _nearestMuseum.image,
		uuid: _nearestMuseum.uuid,
	};
};

const Main = ({ museumsList }) => {
	const { state } = React.useContext(Store);
	const history = useHistory();
	//const [bgImage, setBgImage] = useState('');
	//const [bgImageMuseum, setBgImageMuseum] = useState({image: '', name: '', id: '' });

	const { t } = useTranslation("common");
	const [searchTerm, setSearchTerm] = useState("");
	const { height, width } = useWindowDimensions();
	const isWide = MIN_WIDTH < width;

	const bgMuseum = useMemo(() => {
		if (!museumsList) {
			return;
		}
		if (state.user.lat && state.user.lng) {
			return getBgNearestMusData(museumsList);
		}
		return getBgRandomMusData(museumsList);
	}, [museumsList]);

	const classes = useStyles({
		bgImage: bgMuseum.image,
		isWide: isWide,
		screenHeight: height,
	});
	// search by title or description
	const handleSearch = () => {
		history.push(`/museums?query=${searchTerm}`);
	};

	// find nearest museums
	const handleNearBy = async () => {
		history.push({
			pathname: "/museums",
			search: `lat=${state.user.lat}&lng=${state.user.lng}`,
			position: { lat: state.user.lat, lng: state.user.lng },
		});
	};

	// on enter pressed
	const handleKeyPress = e => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	// TODO: custom hook
	// random background image

	return (
		<div className={classes.main}>
			<div className={classes.searchContainer}>
				<SearchBarBigContainer>
					<SearchBar sb_variant="big" />
				</SearchBarBigContainer>
				{/*<SearchBarMediumContainer>
          <SearchBar sb_variant="medium" />
        </SearchBarMediumContainer>*/}
				<SearchBarSmallContainer>
					<SearchBar sb_variant="small" />
				</SearchBarSmallContainer>
			</div>

			<ReadMoreButton bgImageMuseum={bgMuseum} isWide={isWide}></ReadMoreButton>
		</div>
	);
};
//
export default React.memo(Main);
