import React from "react";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const Container = styled.div`
	display: flex;
	align-items: center;
	padding: ${props => (props.compact ? "6px 6px" : "3px 11px")};
	background: ${props =>
		props.color === "white"
			? "radial-gradient(rgba(255,255,255,0.9),rgba(200,200,200,0.9))"
			: "radial-gradient(rgba(192, 57, 43, 0.8),rgba(192, 57, 43,1))"};
	box-shadow: ${props =>
		props.color === "white" ? "0 0 5px 5px rgba(0,0,0,0.1)" : "none"};
	border-radius: 50px;
	cursor: pointer;
	:not(first-of-type) {
		margin-left: 10px;
	}
`;

const Text = styled.div`
	white-space: nowrap;
	font-size: 14px;
	color: ${props => (props.color === "white" ? "#333" : "#FFF")};
	font-weight: ${props => (props.color === "white" ? "400" : "700")};
`;

const Remove = styled(Close)`
	opacity: 0.8;
`;

const useStyles = makeStyles({
	svg: {
		fill: props => (props.color === "white" ? "#000" : "#FFF"),
		opacity: props => (props.color === "white" ? "0.59" : "0.9"),
		width: props => props.compact && "15px",
		height: props => props.compact && "15px",
	},
});

const FilterHintItem = ({ amount, type, onClick, color, compact }) => {
	const classes = useStyles({ color, compact });
	return (
		<Container
			color={color}
			compact={compact}
			onClick={() => {
				onClick(type);
			}}
		>
			<Text color={color}>{`${amount} ${type}`}</Text>
			<Remove className={classes.svg} />
		</Container>
	);
};

export default FilterHintItem;
