import { useEffect, useState } from "react";
//import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from "history";
const useGoogleAnalytics = () => {
	const [prevPath, setPrevPath] = useState("");

	const history = createBrowserHistory();
	useEffect(() => {
		if (
			typeof window !== "undefined" &&
			prevPath !== history.location.pathname
		) {
			window.gtag("config", "G-1DQGNMERYH", {
				page_title: history.location.pathname,
				page_path: history.location.pathname,
			});
			setPrevPath(history.location.pathname);
		}
	}, [history]);
};

export default useGoogleAnalytics;
