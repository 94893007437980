import React from "react";
import styled from "styled-components";

const TriangleDiv = styled.div`
	bottom: -10px;
	position: absolute;
	width: 0;
	height: 0;
	border-left: 7.5px solid transparent;
	border-right: 7.5px solid transparent;
	border-bottom: 7.5px solid #fff;
	right: 10px;
`;

const Triangle = () => {
	return <TriangleDiv />;
};

export default Triangle;
