import { Helmet } from "react-helmet";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import markerIcon from "../../assets/images/markerIcon.png";
import markerIconOutline from "../../assets/images/markerIconOutline.png";
import * as L from "leaflet";
import React, { useState, useRef, useEffect } from "react";
import useFallbackTrans, { translate } from "../../hooks/useFallbackTrans";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { spiderfyShapePositions } from "../OpenMap/helpers/spiderfyShapePositions";

const Container = styled.div`
	display: flex;
`;
const MapContainerCustom = styled(MapContainer)`
	width: 100%;
	height: 100%;
	.leaflet-tile-pane {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
`;

const getMarkerIcon = iconType => {
	if (iconType === "outline") {
		return markerIconOutline;
	} else {
		return markerIcon;
	}
};

const getMarkerIconSize = iconType => {
	if (iconType === "outline") {
		return 24;
	} else {
		return 30;
	}
};
const getMarkerType = idx => {
	return idx ? "outline" : "normal";
};
const ZOOMLVL = 16;
const boundsExtender = (museums, mapRef) => {
	const _bounds = L.latLngBounds();
	museums.forEach(museum => {
		if (museum && museum.position.lat && museum.position.lng) {
			//console.count("visibleMuseums")
			const latlng = L.latLng(museum.position);
			//console.log(latlng);
			_bounds.extend(latlng);
		}
		if (_bounds.isValid()) {
			mapRef.current.fitBounds(_bounds);
		}
	});
};
const DetailPageMap = ({ museums }) => {
	console.log("DETAILPAGEMAP");
	console.log(museums);
	//console.log([museums[0].position.lat, museums[0].position.lng]);
	const currentMuseum = museums[0];
	const mapRef = useRef();
	//const [mapCenter, setMapCenter] = useState(museums[0].position);

	useEffect(() => {
		//setMapCenter(museums[0].position);
		if (!mapRef.current) {
			return;
		}

		if (museums.length > 1) {
			boundsExtender(museums, mapRef);
		} else {
			mapRef.current.setView(currentMuseum.position, ZOOMLVL);
		}
	}, [museums, currentMuseum]);
	return (
		<Container>
			<Helmet>
				<link
					rel="stylesheet"
					href="https://unpkg.com/leaflet/dist/leaflet.css"
				/>

				<link
					rel="stylesheet"
					href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
				/>
			</Helmet>
			<MapContainerCustom
				whenCreated={mapObj => {
					mapRef.current = mapObj;
					museums.length > 1 && boundsExtender(museums, mapRef);
				}}
				center={currentMuseum.position}
				zoom={ZOOMLVL}
				scrollWheelZoom={false}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<MarkerClusterGroup spiderfyShapePositions={spiderfyShapePositions}>
					{museums.map(
						(_museum, idx) =>
							_museum &&
							!!_museum.position.lat &&
							!!_museum.position.lng && (
								<Marker
									key={_museum.uuid + "_detailmarker"}
									position={_museum.position}
									icon={L.icon({
										className: "marker_detailpage_" + _museum.uuid, //"custom-icon",
										iconUrl: getMarkerIcon(getMarkerType(idx)),
										iconSize: getMarkerIconSize(getMarkerType(idx)),
										//html: iconHtml,
										iconAnchor: [
											getMarkerIconSize(getMarkerType(idx)) / 2,
											getMarkerIconSize(getMarkerType(idx)),
										],
									})}
								>
									<Tooltip permanent={true} direction="bottom">
										{translate(_museum.name, "no")}
									</Tooltip>
								</Marker>
							),
					)}
				</MarkerClusterGroup>
			</MapContainerCustom>
		</Container>
	);
};
export default DetailPageMap;
