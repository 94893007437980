import React from "react";
import styled from "styled-components";
import Carousel from "../components/Carousel/carousel";
import SearchBar from "../components/SearchBar/searchBar";

const TestPage = () => {
	const Container = styled.div`
		display: flex;
		flex-direction: column;
		background-color: #00000060;
		flex: 1;
		min-height: 100vh;
		color: white;
		justify-content: center;
		align-items: center;
		padding-top: 100px;
	`;

	return (
		<Container>
			{/*<Carousel></Carousel>*/}

			<SearchBar sb_variant="big" />
			<SearchBar sb_variant="medium" />
			<SearchBar sb_variant="small" />
		</Container>
	);
};

export default TestPage;
