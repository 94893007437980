import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	Tooltip,
	useMapEvents,
} from "react-leaflet";
import * as L from "leaflet";
import markerIcon from "../../assets/images/markerIcon.png";
import markerIconOutline from "../../assets/images/markerIconOutline.png";
import useFallbackTrans from "../../hooks/useFallbackTrans";
import styled, { createGlobalStyle } from "styled-components";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";

const PopupButton = styled(Button)`
	text-transform: none;
`;

const getMarkerIcon = iconType => {
	if (iconType === "outline") {
		return markerIconOutline;
	} else {
		return markerIcon;
	}
};

const getMarkerIconSize = iconType => {
	if (iconType === "outline") {
		return 24;
	} else {
		return 30;
	}
};

const CustomArrowRightIcon = styled(KeyboardArrowRight)`
	color: var(--blaze500);
	height: 22px;
`;

const MapMarker = ({
	map,
	museum,
	isVisible,
	tooltipFixUpdateForcer,
	currentMarkerData,
	setCurrentMarkerData,
	prevDocumentRef,
	allMarkersRef,
}) => {
	//const markerRef = useRef();
	//museum.marker = markerRef;
	const [currentMarkerIcon, setCurrentMarkerIcon] = useState("normal");
	const setCurrentMarkerIconRef = useRef();

	const history = useHistory();
	//console.count("mapmarkers redrawn");
	return (
		<Marker
			ref={mRef => {
				allMarkersRef.current[museum.uuid] = mRef;
			}}
			id={museum.uuid + "_marker"}
			//className={isVisible && "hidden-marker"}
			data-museumUuid={museum.uuid}
			position={
				isVisible
					? /*? currentMarkerIcon === "normal"
						? [museum.position.lat + 0.0002, museum.position.lng + 0.0002]
						:*/ [museum.position.lat, museum.position.lng]
					: [0, 0]
			}
			riseOnHover={true}
			eventHandlers={{
				click: e => {
					console.log(museum);
					console.log(map.getBounds());
					console.log(e);
					console.log(allMarkersRef.current[museum.uuid]);
					//setTimeout(() => console.log("test"), 1);
					//setCurrentMarkerIconRef.current = setCurrentMarkerIcon;
					if (currentMarkerData.currentMuseum) {
						currentMarkerData.setCurrentMarkerIconRef.current("outline");
					}
					/*setTimeout(() => {
						setCurrentMarkerData({
							currentMuseum: museum,
							setCurrentMarkerIconRef: setCurrentMarkerIconRef,
						});
						setCurrentMarkerIcon("normal");
					}, 1000);*/

					if (prevDocumentRef.current) {
						prevDocumentRef.current.classList.remove("selected-item");
					}
					console.log(
						"allMarkersRef.current[museum.uuid].options.scrollList: ",
						allMarkersRef.current[museum.uuid].options.scrollList,
					);
					if (allMarkersRef.current[museum.uuid].options.scrollList === true) {
						const currentItem = document.getElementById(museum.uuid);
						currentItem.classList.add("selected-item");
						currentItem.scrollIntoView();
						prevDocumentRef.current = currentItem;
					}
					allMarkersRef.current[museum.uuid].options.scrollList = true;
					//history.push({ pathname: `/museums/${museum.uuid}` });
					//map.setView([museum.position.lat, museum.position.lng]);
				},
			}}
			//ref={markerRef}
			icon={L.icon({
				className: "marker_" + museum.uuid, //"custom-icon",
				iconUrl: getMarkerIcon(currentMarkerIcon),
				iconSize: getMarkerIconSize(currentMarkerIcon),
				//html: iconHtml,
				iconAnchor: [
					getMarkerIconSize(currentMarkerIcon) / 2,
					getMarkerIconSize(currentMarkerIcon),
				],
			})}
		>
			<Tooltip
				direction="bottom"
				permanent={true}
				opacity={0.9}
				eventHandlers={{
					click: () => {
						console.log(museum);
					},
				}}
			>
				{useFallbackTrans(museum.name)}
			</Tooltip>
			<Popup id={museum.uuid + "_marker_popup"} offset={[0, -30]}>
				<PopupButton
					endIcon={<CustomArrowRightIcon />}
					onClick={() => {
						history.push({ pathname: `/museums/${museum.uuid}` });
					}}
				>
					Se mer
				</PopupButton>
			</Popup>
		</Marker>
	);
};

export default React.memo(MapMarker);
