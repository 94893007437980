import List from "@material-ui/core/List";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Item from "../Item/item";
import { Button } from "@material-ui/core";
import SearchBar from "../SearchBar/searchBar";
import styled from "styled-components";
import { text_sm } from "../../style/styles";
import { useContext } from "react";
import { Store } from "../../Store";
import {
	isVisibleInList,
	resetDisabledByAll,
	resetVisibleBothAll,
} from "../../helpers/filterHelpers";

const smallScreenWidth = 860;

const Container = styled.div`
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	scroll-behavior: smooth;
	width: 100%;
`;

const NoMuseumsContainer = styled.div`
	justify-content: center;
	flex: 1;
	display: flex;

	padding-top: 20px;
	@media only screen and (min-height: ${smallScreenWidth}px) {
		margin: 20px;
	}
`;

/*

  main: {
    position: "relative",
    boxSizing: "border-box",
    display: props =>
      props.isWide || (!props.isWide && props.active) ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "flex-start",
    //alignItems: props => (props.noMuseums ? 'center' : 'flex-start'),
    height: "100%",
    overflowY: "auto",
    scrollBehavior: "smooth",
    overflowX: "hidden",
    minWidth: props => (props.isWide ? "400px" : "unset"),
    width: props => (!props.isWide ? "100%" : "40vw"),
    boxShadow: "1px 1px 7px -1px rgba(0,0,0,0.25)",
    zIndex: 1,
  },

*/

const SearchBarContainer = styled.div`
	display: flex;
	background-color: var(--Museumsbakgrunn);
	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 16px;
	flex-shrink: 0;
	@media only screen and (min-width: ${smallScreenWidth}px) {
		display: none;
	}
`;

const CustomList = styled(List)`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	padding-top: 0;
`;

const InfoItem = styled(Button)`
	display: flex;
	flex-direction: column;
	${text_sm}
	margin-top: 15px;
	margin-bottom: 15px;
	text-transform: none;
	white-space: pre-wrap;
	cursor: pointer;
`;

const ListView = ({
	listMuseums = [],
	setListMuseums,

	searchTerm,
	active,
	setSearchTerm,
	search,
	initialSearch,
	isSearching,
	setResetMemoizedMuseums,
	searchSubmitHandler,
	mapRef,
	allMarkersRef,
	searchOnMove = null,
	setSearchOnMove = null,
}) => {
	//const classes = useStyles({ isWide, noMuseums: R.isEmpty(museums), active });
	const { t } = useTranslation("common");
	const { state, dispatch } = useContext(Store);
	const [showFilters, setShowFilters] = useState(false);
	const [showCategories, setShowCategories] = useState(false);
	const [showCounties, setShowCounties] = useState(false);

	//const [listMuseums, setListMuseums] = useState(museums);

	//const memoizedMuseums = React.useMemo(() => museums, [museums]);

	const clearAllFilters = () => {
		dispatch({
			type: "SET_SETTINGS_FILTERSCOUNTIES",
			payload: [],
		});
		dispatch({
			type: "SET_SETTINGS_FILTERSCATEGORIES",
			payload: [],
		});
		searchSubmitHandler("");
		setSearchOnMove(false);
		setResetMemoizedMuseums(true);
		resetDisabledByAll(state.data.museumsList);
	};

	useEffect(() => {
		setListMuseums(
			state.data.museumsList
				.filter(_museum => isVisibleInList(_museum))
				.sort((a, b) => a.distance - b.distance),
		);
	}, [
		state.settings.filtersCategories,
		state.settings.filtersCounties,
		searchTerm,
		state.data.museumsList,
	]);

	return (
		<Container
			onScroll={evt => {
				if (!state.settings.listScrolled && evt.target.scrollTop) {
					dispatch({
						type: "SET_SETTINGS_LISTSCROLLED",
						payload: true,
					});
				} else if (state.settings.listScrolled && !evt.target.scrollTop) {
					dispatch({
						type: "SET_SETTINGS_LISTSCROLLED",
						payload: false,
					});
				}
			}}
			id="list-container"
		>
			<div id="list-top"></div>
			<SearchBarContainer>
				<SearchBar
					sb_variant="medium"
					onMapPage={true}
					searchSubmitHandler={searchSubmitHandler}
					searchOnMove={searchOnMove}
					setSearchOnMove={setSearchOnMove}
					hasShadow={false}
					data={listMuseums}
					showHits={true}
					searchTermProp={searchTerm}
				></SearchBar>
			</SearchBarContainer>

			{/*R.isEmpty(museums) ? (
				<NoMuseumsContainer>
					{t("general.noSearchResultsFound")}
				</NoMuseumsContainer>
			) : (*/}
			<CustomList>
				{state.data.museumsList
					.filter(_museum => _museum.distance && isVisibleInList(_museum))
					.sort((a, b) => a.distance - b.distance)
					.map((currentMuseum, i) => (
						/*currentMuseum.uuid === "d2515d4e-47b5-4f97-b9e7-527a1b8e73bc" && // TESTING MAIHAUGEN*/

						<Item
							museums={state.data.museumsList}
							currentMuseum={currentMuseum}
							mapRef={mapRef}
							key={currentMuseum.uuid}
							allMarkersRef={allMarkersRef}
						/>
					))}
				{searchOnMove ? (
					<InfoItem onClick={clearAllFilters}>
						{t("list.onMoveInfoMessage")}
					</InfoItem>
				) : /*searchTerm &&*/ listMuseums.length <
				  state.data.museumsList.length ? (
					<InfoItem onClick={clearAllFilters}>
						{listMuseums === 0
							? t("list.notFoundInfoMessage")
							: t("list.searchInfoMessage")}
					</InfoItem>
				) : (
					<></>
				)}
			</CustomList>
			{/*)}*/}
		</Container>
	);
};

export default React.memo(ListView);
