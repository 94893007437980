import {
	Card,
	CardContent,
	CardMedia,
	styled as styledMD,
} from "@material-ui/core";
import TestImage from "../../assets/images/countyPhotos/matthias-tillen-F9zzXiYhJ54-unsplash.jpg";
import React from "react";
import styled from "styled-components";
import { countiesSettings } from "./countiesSettings";
import { categoriesSettings } from "./categoriesSettings";
import { text_sm, text_xl_bold } from "../../style/styles";
import { useTranslation } from "react-i18next";

const MIN_WIDTH = "800px";

const CardContainer = styled.div`
	width: 80%;
	min-width: 300px;
	height: 150px;

	@media only screen and (min-width: ${MIN_WIDTH}) {
		width: 302px;
		//height: min-content;
		height: 280px;
	}
`;

const CardTitle = styled.h2`
	${text_xl_bold}
	text-align: left;
`;

const CardMuseumCount = styled.p`
	${text_sm}
	text-align: left;
`;

const CustomCard = styled(Card)`
	&& {
		height: 100%;
		box-shadow: none;
		border-radius: 24px;
		display: flex;

		@media only screen and (min-width: ${MIN_WIDTH}) {
			border-radius: 24px;
			display: block;
		}
	}
`;

const CustomCardMedia = styled(CardMedia)`
	&& {
		width: 118px;

		@media only screen and (min-width: ${MIN_WIDTH}) {
			width: 100%;
		}
	}
`;

const CustomCardContent = styled(CardContent)`
	display: flex;
	flex-direction: column;
`;

const CategoryCard = ({ title, numMuseums, isCategory, uuid, count }) => {
	const counties = countiesSettings;
	const categories = categoriesSettings;
	const cardSettings = { counties, categories };
	const { t } = useTranslation("common");
	const getImage = uuid => {
		const curr_obj = cardSettings[isCategory ? "categories" : "counties"].find(
			el => {
				return el.uuid === uuid;
			},
		);
		if (curr_obj) {
			return curr_obj.img;
		}
		return;
	};

	return (
		<CardContainer>
			<CustomCard>
				<CustomCardMedia
					component="img"
					image={getImage(uuid) ? getImage(uuid) : TestImage}
					height="160px"
				/>

				<CustomCardContent>
					<CardMuseumCount>
						{count} {t("plurals.museum", { count: count }).toLowerCase()}
					</CardMuseumCount>
					<CardTitle>{title}</CardTitle>
				</CustomCardContent>
			</CustomCard>
		</CardContainer>
	);
};

export default CategoryCard;
