import { Point } from "leaflet";

// Customized to prevent overlapping labels
export const spiderfyShapePositions = (count, centerPt) => {
	let distanceFromCenter = 60, // radius
		markerDistance = 70, // distance from other marker
		lineLength = markerDistance * (count - 1),
		lineStart = centerPt.y - lineLength / 2,
		res = [],
		i;

	res.length = count;

	for (i = count - 1; i >= 0; i--) {
		res[i] = new Point(
			centerPt.x + distanceFromCenter,
			lineStart + markerDistance * i,
		);
	}

	return res;
};