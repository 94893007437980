export const openMarkerClustersAndMarkerPopup = (
	mapRef,
	allMarkersRef,
	currentMuseum,
) => {
	mapRef.current.off("moveend");
	/*const getMarkerElement = () => {
        return document.querySelectorAll(
            ".marker_" + currentMuseum.uuid,
        )?.[0];
    };*/
	const markerElementRef = allMarkersRef.current[currentMuseum.uuid];
	//const markerElement = getMarkerElement();
	/*document.getElementById(
        currentMuseum.uuid + "_marker",*/

	/*if (markerElement) {
        markerElement.click();
    } */
	if (markerElementRef._icon) {
		console.log("FOUND _ICON", { markerElementRef });
		markerElementRef.options.scrollList = false;
		//markerElementRef._icon.click();
		openPopup(markerElementRef, mapRef);
	} else {
		const clusters = [];
		//let _markerElement = getMarkerElement();
		let _currentElement = markerElementRef;
		while (/*!_markerElement &&*/ _currentElement.__parent) {
			clusters.push(_currentElement);
			console.groupCollapsed("inwhile");
			console.log("_currentElement start");
			console.log(_currentElement);
			console.log("markerElementRef");
			console.log(markerElementRef);
			if (_currentElement._icon) {
				console.log("break");
				console.groupEnd("inwhile");
				break;
			}
			_currentElement = _currentElement.__parent
				? _currentElement.__parent
				: _currentElement._parent;

			//_markerElement = getMarkerElement();
			//console.log("_markerElement");
			//console.log(_markerElement);
			console.groupEnd("inwhile");
		}

		console.log(clusters);
		console.log("FORLOOP");
		for (let i = clusters.length - 1; i >= 0; i--) {
			const _icon = clusters[i]._icon;
			console.log(clusters[i]);
			markerElementRef.options.scrollList = false;
			_icon && _icon.click();
		}
		openPopup(markerElementRef, mapRef);
	}
};

export const openPopup = (marker, mapRef) => {
	const popup = marker.getPopup();
	if (popup) popup.openOn(mapRef.current);
};
