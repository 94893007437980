import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { Settings } from "../../config/Settings";
import { mapStyle } from "../Theme/theme";
import markerImg from "../../assets/images/markerStaticmap.png";

const useStyles = makeStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		backgroundColor: "#fff",
		borderRadius: 5,
		//boxShadow: '1px 1px 7px -1px rgba(0,0,0,0.25)',
		justifyContent: "flex-start",
		minHeight: "min-content",
		cursor: "pointer",
		"& img": {
			borderRadius: 5,
			objectFit: "cover",
			maxWidth: "100%",
			maxWeight: "100%",
			height: "auto",
		},
	},
});

export default ({ lat, lng, isWide }) => {
	const classes = useStyles();
	const size = isWide ? "1000x1000" : "500x500";
	const image = document.querySelector("img");
	/*if (image) {
    image.onclick = () => {      
      window.open(`https://www.google.com/maps/dir//${lat},${lng}`, "_blank")
    };
  }*/
	const convertToStaticMapStyle = () => {
		let output = "";
		mapStyle.forEach((el, idx) => {
			output += "&style=";
			if (el.featureType) {
				output += `feature:${el.featureType}|`;
			}
			output += `element:${el.elementType}|`;
			output += `${Object.keys(el.stylers[0])}:${Object.values(el.stylers[0])
				.toString()
				.replace("#", "0x")}`; //${idx + 1 === mapStyle.length ? "" : "|"}
		});
		//console.log(output);
		return output;
	};
	const staticMapStyle = convertToStaticMapStyle();

	//console.log("\n====mapStyle: ",mapStyle)
	//console.log(markerImg)
	return (
		<div className={classes.container}>
			<StaticGoogleMap
				size={size}
				scale="2"
				zoom="14"
				apiKey={Settings.GOOGLE_API_KEY_STATIC}
				style={staticMapStyle}
			>
				<Marker
					iconURL={
						"http://museumsguiden.no/api/dm.png" /*encodeURIComponent("tinyurl.com/7xtdykaf")*/
					}
					location={`${lat}, ${lng}`}
					color="0xfb5f0a" /*label="M"*/
				/>
			</StaticGoogleMap>
		</div>
	);
};
