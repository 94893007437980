import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

const useStyles = makeStyles({
	imgLoaderRoot: {
		position: "relative",
	},

	imgThumbnail: {
		width: props => `${props.imageWidth}px`,
		height: props => `${props.imageHeight}px`,
		objectFit: "cover",
	},

	imgOverlay: {
		position: "absolute",
		opacity: "1",
		transition: "opacity ease-in 0.2s",
	},

	hide: {
		opacity: "0",
	},
});

/*
    Loads an image with a image with a lower resolution on top. 
    The low res image is hidden when the hires image has loaded.
*/

const ImgLoader = ({
	url,
	altTxt,
	lowres = "57x57-crop",
	hires = "400x400",
	imageHeight,
	imageWidth,
}) => {
	const classes = useStyles({
		imageWidth: imageWidth,
		imageHeight: imageHeight,
	});
	const [imgLoaded, setImgLoaded] = useState(false);
	const hiresRef = useRef(null);
	const handleOnLoad = () => {
		//console.log("image loaded ", url);
		setImgLoaded(true);
	};

	useEffect(() => {
		return () => {
			hiresRef.current.removeEventListener("load", handleOnLoad);
		};
	}, []);

	return (
		<div className={classes.imgLoaderRoot}>
			<img
				className={clsx(
					classes.imgThumbnail,
					classes.imgOverlay,
					imgLoaded && classes.hide,
				)}
				alt={altTxt}
				src={`${url}?dimension=${lowres}`}
			></img>
			<img
				ref={hiresRef}
				className={classes.imgThumbnail}
				alt={altTxt}
				src={`${url}?dimension=${hires}`}
				onLoad={handleOnLoad}
			></img>
		</div>
	);
};

export default ImgLoader;
