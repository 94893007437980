import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const baseStyles = createGlobalStyle`
  ${reset}

  html {
    height: 100%;
  }

  body {
    height: 100%;
    font-family: 'AkkuratLLWeb', sans-serif;
    background-color: #f5f7f9;
    display: flex;
  }

  div#root {
    overflow: auto;    
    display: flex;
    flex: 1;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition-delay: 99999s;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'AkkuratLLWeb', sans-serif;
  }
`;

export default baseStyles;
