import React, { useEffect } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	mark: {
		position: "absolute",
		backgroundColor: "white",
		zIndex: 99,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		boxShadow: "1px 1px 7px -1px rgba(0,0,0,0.25)",
		padding: "5px",
		borderRadius: "10px",
		marginTop: "24px",
		top: "0px",
		left: "50%",
		transform: "translateX(-50%)",
		width: "fit-content",
	},
});

const SearchOnMoveCheckbox = ({ searchOnMove, setSearchOnMove }) => {
	const { t } = useTranslation("common");
	const classes = useStyles();

	const handleChange = () => {
		setSearchOnMove(!searchOnMove);
	};

	useEffect(() => {
		setSearchOnMove(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.mark}>
			<FormControlLabel
				style={{ marginLeft: 0 }}
				label={t("general.searchWhileMoving")}
				control={
					<Checkbox
						color="default"
						checked={searchOnMove}
						onChange={handleChange}
						name="searchOnMove"
					/>
				}
			/>
		</div>
	);
};

export default SearchOnMoveCheckbox;
