import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListIcon from "@material-ui/icons/List";
import { useTranslation } from "react-i18next";
import MapIcon from "@material-ui/icons/Map";
import FilterHint from "../FilterHint/filterhint";
import styled from "styled-components";
import { Button, IconButton } from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import { useContext } from "react";
import { Store } from "../../Store";

const useStyles = makeStyles({
	footerButton: {
		//position: 'absolute',
		//bottom: 0,
		//width: '100%',
		height: props => (props.activeMarker ? 0 : 48),
		display: "flex",

		alignSelf: "flex-end",
		alignItems: "center",
		zIndex: 999,
		transition: "height 0.8s",
		cursor: "pointer",
		userSelect: "none",
	},
	inner: {
		flex: 1,
		display: "flex",
		padding: "0 15px",
		alignItems: "center",
	},
	label: {
		paddingLeft: 5,
		fontSize: props => props.activeMarker && 0,
		transition: "font-size 0.8s",
	},
	icon: {
		height: props => props.activeMarker && 0,
		transition: "height 0.8s",
	},
});

const Container = styled.div`
	display: flex;
	width: 100%;
	background-color: #eceff1;
	justify-content: space-between;
	box-shadow: 0px -1px 3px #d6d6d6;
`;

const ScrollTopButton = styled(IconButton)`
	margin-left: 20px;
`;

const Toggler = ({ isMapActive, onPress, activeMarker, active }) => {
	const { t } = useTranslation("common");
	const classes = useStyles({ activeMarker, active });
	const { state, dispatch } = useContext(Store);

	return (
		<Container>
			<div className={classes.footerButton}>
				<div className={classes.inner} onClick={() => onPress(!isMapActive)}>
					{isMapActive ? (
						<ListIcon className={classes.icon} />
					) : (
						<MapIcon className={classes.icon} />
					)}
					<Typography className={classes.label}>
						{isMapActive ? t("general.showList") : t("general.showMap")}
					</Typography>
				</div>
				{false && <FilterHint color="black" compact />}
			</div>

			{!isMapActive && state.settings.listScrolled && (
				<ScrollTopButton
					onClick={() => {
						document.getElementById("list-top").scrollIntoView();
					}}
				>
					<Publish />
				</ScrollTopButton>
			)}
		</Container>
	);
};

export default Toggler;
