import CardMedia from "@material-ui/core/CardMedia";
import ListItem from "@material-ui/core/ListItem";
import PLACEHOLDER_IMG from "../../assets/images/img_placeholder.png";

import * as R from "ramda";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Store } from "../../Store";
import useFallbackTrans, { translate } from "../../hooks/useFallbackTrans";
import styled from "styled-components";
import { HeroMarkerSolid, HeroLibrarySolid } from "../Icons/icons";
import {
	openMarkerClustersAndMarkerPopup,
	openPopup,
} from "../../helpers/openMarkerPopup";

const MIN_WIDTH_DESKTOP = "1200px";
const MIN_WIDTH_MOBILE = "800px";
const MARGIN = "16px";
const MARGIN_SMALL = "10px";

const ListItemCustom = styled(ListItem)`
	display: flex;
	position: relative;
	padding: 0;
	& > .MuiListItem-gutters {
		padding: 0;
	}

	.selected-item {
		border: 2px solid #fdbf9d;
	}

	@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
		padding: 8px 16px 8px 24px;
		&:first-of-type {
			padding-top: 0;
		}
	}

	@media only screen and (max-width: ${MIN_WIDTH_DESKTOP}) {
		&:not(:first-of-type) {
			border-top: 1px solid var(--Museumsbakgrunn);
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid var(--Museumsbakgrunn);
		}
	}
`;

const Card = styled.article`
	display: grid;
	position: relative;
	justify-content: left;
	align-items: start;
	//grid-template-columns: repeat(2, auto);
	grid-template:
		"img  info" auto
		"extra  extra" 23px / 136px 1fr;
	background-color: #fff;
	width: 100%;
	cursor: pointer;
	@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
		border-radius: 16px;
		grid-template:
			"img  info" auto
			"img  extra" 23px / 185px auto;
	}

	/* fake outline */
	&::after {
		content: "";
		position: absolute;
		border: 2px solid #fdbf9d;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
		z-index: 2;
		@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
			border-radius: 16px;
		}
	}

	&:hover {
		&::after {
			opacity: 1;
		}
	}

	&:focus {
		//background-color: black;
		box-shadow: 0 0 5px black;
	}
`;

const CardImageContainer = styled.div`
	grid-area: img;
	position: relative;
	background-image: url(${props => props.bgimage});
	background-color: var(--gray500);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	margin: ${MARGIN_SMALL};
	height: 80px;
	width: 120px;
	border-radius: 6px;
	@media only screen and (min-width: ${MIN_WIDTH_MOBILE}) {
		margin: ${MARGIN};
	}

	@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
		margin: 0;
		border-radius: 16px 0 0 16px;
		height: 120px;
		width: 185px;
	}
`;

const CardInfoContainer = styled.div`
	grid-area: info;
	margin-top: ${MARGIN_SMALL};
	margin-left: ${MARGIN_SMALL};
	@media only screen and (min-width: ${MIN_WIDTH_MOBILE}) {
		margin: ${MARGIN};
	}
`;

const CardTitle = styled.h2`
	font-size: 17px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: -0.25px;
	text-align: left;
	color: var(--Museumssvart);
	overflow-wrap: normal;
`;

const CardSubtitle = styled.p`
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0px;
	text-align: left;
	color: var(--Museumssvart);
`;

const CardExtraContainer = styled.div`
	grid-area: extra;
	display: flex;
	justify-content: space-between;
	margin-left: ${MARGIN_SMALL};
	margin-right: ${MARGIN_SMALL};
	@media only screen and (min-width: ${MIN_WIDTH_MOBILE}) {
		margin-left: ${MARGIN};
		margin-right: ${MARGIN};
	}
`;

const CardExtraText = styled.p`
	font-size: 11px;
	font-weight: 400;
	line-height: 13px;
	letter-spacing: -0.125px;
	opacity: 0.7;
	color: var(--Museumssvart);
`;

const MuseumIconStyled = styled(HeroLibrarySolid)`
	display: flex;
	align-items: center;
	padding: 0;
	padding-right: 5px;
	height: 13px;
	opacity: 0.7;
	width: 13px;
`;
const DistanceIconCustomBlack = styled(HeroMarkerSolid)`
	display: flex;
	align-self: center;
	height: 17px;
	opacity: 0.7;
	width: 20px;
`;

const DistanceIconCustomWhite = styled(HeroMarkerSolid)`
	display: flex;
	height: 15px;
	width: 17px;
	color: #fff;
	align-self: center;
`;

const ExtraTextAndIconContainer = styled.div`
	display: flex;
	align-items: center;

	@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
		display: ${props => (props.hideOnWide ? "none" : "flex")};
	}
`;

const DistanceContainer = styled.div`
	display: none;
	@media only screen and (min-width: ${MIN_WIDTH_DESKTOP}) {
		display: flex;
		justify-content: center;
		align-items: baseline;
		position: absolute;
		bottom: 6px;
		right: 8px;
		height: 24px;
		background-color: #1f1f1f4f;
		z-index: 2;
		border-radius: 5px;
		padding: 4px;
		box-sizing: border-box;
		backdrop-filter: blur(5px);
	}
`;
const DistanceText = styled.p`
	font-size: 11px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: -0.125px;
	color: #fff;
`;

const CardContainer = React.memo(props => <CardMedia {...props} />);

const Item = ({ currentMuseum, museums, mapRef, allMarkersRef }) => {
	//const state = useContext(Store);
	const history = useHistory();

	const { t } = useTranslation("common");
	const { state } = useContext(Store);
	const [isVisible, setIsVisible] = useState(false);
	const setIsVisibleRef = useRef(setIsVisible);
	const containerRef = useRef(null);

	// Navigate to details screen
	const navigateTo = (event, data) => {
		// Skip nested link clicks
		if (event.target) {
			const dataType = event.target.getAttribute("data-type");

			if (dataType === "part-of-link") {
				return;
			}
		}

		history.push({
			pathname: `/museums/${R.prop("uuid", data)}`,
			state: { data },
		});
	};

	const title = useFallbackTrans(currentMuseum.name);
	const id = R.prop("uuid", currentMuseum);
	const distance = R.prop("distance", currentMuseum);

	/* distance blir borte i m variablen på 3.? redraw*/
	const isPartOfId = currentMuseum.isPartOf;

	const isPartOf =
		!R.isNil(state.data.museumsList) &&
		!R.isEmpty(state.data.museumsList) &&
		state.data.museumsList.find(m => m.uuid === isPartOfId);

	const hasPart = currentMuseum.hasParts;

	/* HVORDAN BØR DENNE HÅNDTERES? */
	const numParts =
		!R.isNil(hasPart) &&
		!R.isEmpty(hasPart) &&
		hasPart.reduce(acc => acc + 1, 0); // counts departments

	const getCategory = () => {
		const _categories = currentMuseum.categories.map(CMC_el => {
			for (const [key, value] of Object.entries(state.data.categoriesList)) {
				if (CMC_el in value.subCategories) {
					return value.alternativeName;
				}
			}
		});
		//console.log("_CATEGORIES: ", _categories);

		return _categories;
	};

	const createCategoriesString = () => {
		const _categories = getCategory();
		const translated_list = _categories.map(el =>
			translate(el, state.settings.locale),
		);
		const unique = Array.from(new Set(translated_list)).sort();
		//console.log(unique);
		return unique.reduce(
			(accStr, el) => (accStr ? `${accStr}, ${el}` : el),
			"",
		);
	};

	const formatDistance = distMeters => {
		return distMeters >= 1000
			? (distMeters / 1000).toFixed(1).toString().replace(".", ",") + " km"
			: Math.floor(distMeters) +
					" m"; /* Shows distance in km if >= 1000 m else in meters */
	};
	//console.log(createCategoriesString());

	const options = {
		root: null,
		rootMargin: "2000px 0px 2000px 0px",
		threshold: 0,
	};
	const observerCallbackFn = entries => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	useEffect(() => {
		if ("IntersectionObserver" in window) {
			const observer = new IntersectionObserver(observerCallbackFn, options);
			const _containerRefCurrent = containerRef.current;
			if (containerRef.current) {
				observer.observe(containerRef.current);
			}
			return () => {
				if (_containerRefCurrent) {
					observer.unobserve(_containerRefCurrent);
				}
			};
		} else {
			setIsVisibleRef.current(true);
		}
	}, [containerRef, options]);

	return (
		<ListItemCustom
			disableGutters
			alignItems="center" //"flex-start"
			key={id}
		>
			<Card
				onClick={event => {
					if (mapRef.current) {
						console.log({
							museum: currentMuseum.position,
							map: mapRef.current._lastCenter,
						});
						const diffArr = [
							Math.abs(
								currentMuseum.position.lat - mapRef.current._lastCenter.lat,
							),
							Math.abs(
								currentMuseum.position.lng - mapRef.current._lastCenter.lng,
							),
						];
						const latLngmaxDiff = 0.001;
						if (diffArr[0] > latLngmaxDiff || diffArr[1] > latLngmaxDiff) {
							console.log("FLYTO: diff: ", diffArr);
							mapRef.current.off("moveend");
							mapRef.current
								.flyTo(currentMuseum.position, 15)
								.on("moveend", () => {
									openMarkerClustersAndMarkerPopup(
										mapRef,
										allMarkersRef,
										currentMuseum,
									);
								});
						} else {
							console.log("NO NEED TO FLYTO: diff: ", diffArr);
							openMarkerClustersAndMarkerPopup(
								mapRef,
								allMarkersRef,
								currentMuseum,
							);
						}

						/*mapRef.current.setView(currentMuseum.position, 16, {
							animate: true,
							duration: 2.5,
						});*/
					} else {
						console.warn("no mapref");
					}

					//navigateTo(event, currentMuseum)
				}}
				tabIndex={0}
				ref={containerRef}
				id={currentMuseum.uuid}
				onKeyPress={evt => {
					if (evt.key === "Enter" || evt.key === " ") {
						navigateTo(evt, currentMuseum);
					}
				}}
			>
				<CardImageContainer
					bgimage={
						isVisible
							? `${
									currentMuseum.image ? currentMuseum.image : PLACEHOLDER_IMG
							  }?dimension=250x250`
							: PLACEHOLDER_IMG
					}
				>
					<DistanceContainer>
						<DistanceIconCustomWhite />
						<DistanceText>{formatDistance(distance)}</DistanceText>
					</DistanceContainer>
				</CardImageContainer>
				<CardInfoContainer>
					<CardTitle>{title}</CardTitle>
					{isPartOf && (
						<CardSubtitle>{`${t("general.includedIn")} ${translate(
							isPartOf.name,
							state.settings.locale,
						)}`}</CardSubtitle>
					)}
				</CardInfoContainer>
				<CardExtraContainer>
					<ExtraTextAndIconContainer>
						<MuseumIconStyled />
						<CardExtraText>{createCategoriesString()}</CardExtraText>
					</ExtraTextAndIconContainer>

					<ExtraTextAndIconContainer hideOnWide>
						<DistanceIconCustomBlack />
						<CardExtraText>{formatDistance(distance)}</CardExtraText>
					</ExtraTextAndIconContainer>
				</CardExtraContainer>
			</Card>
		</ListItemCustom>
	);
};

export default React.memo(Item);
