export class Settings {
	static GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
	static GOOGLE_API_KEY_STATIC = process.env.REACT_APP_STATIC_GOOGLE_MAPS_KEY;
	static APP_VERSION = process.env.REACT_APP_VERSION;
	static RANDOM_IMG_URL = "https://apigateway.dimu.org/museum-api/museums";
	static URL_KULTURIT = "https://kulturit.org";
	static APP_URL = "https://beta.museumsguiden.org";
	static IFRAME_URL = "https://widgets.dimu.org";
	static MUSEUMS_API_URL = "https://apigateway.dimu.org/museum-api";
	static KULTURNAV_API_URL = "https://kulturnav.org/api";
	static KP_API_URL = "https://api.kulturpunkt.org/v3";
	static KP_API_KEY = process.env.REACT_APP_KP_API_KEY;
	static DIMU_API_KEY = process.env.REACT_APP_DIMU_KEY;
	static DIMU_API_URL = "https://api.dimu.org/api";
	static MG_UUID = "eb04bd82-30cd-4f7c-9f71-51f009c3270b";
	static MG_CATEGORIES_UUID = "b881deb4-b9a9-48b3-bf0c-f311ad1e2763";
	static MG_COUNTIES_UUID = "044fbd0f-69f2-4aba-9f33-7b7c1aea4fb0";
}
