import { useContext, useMemo } from "react";
import { Store } from "../Store";

const fallbackArr = {
	no: [
		"no",
		"nb",
		"nob",
		"nor",
		"nn",
		"nno",
		"sv",
		"swe",
		"en",
		"se",
		"sme",
		"de",
		"sp",
		"fr",
	],
	nb: [
		"no",
		"nb",
		"nob",
		"nor",
		"nn",
		"nno",
		"sv",
		"swe",
		"en",
		"se",
		"sme",
		"de",
		"sp",
		"fr",
	],
	nn: [
		"nn",
		"nno",
		"no",
		"nb",
		"nob",
		"nor",
		"sv",
		"swe",
		"en",
		"se",
		"sme",
		"de",
		"sp",
		"fr",
	],
	sv: [
		"sv",
		"swe",
		"no",
		"nb",
		"nob",
		"nor",
		"nn",
		"nno",
		"en",
		"se",
		"sme",
		"de",
		"sp",
		"fr",
	],
	se: [
		"se",
		"sme",
		"smn",
		"smj",
		"smi",
		"sms",
		"sma",
		"no",
		"nb",
		"nob",
		"nor",
		"nno",
		"nn",
		"sv",
		"en",
		"de",
		"sp",
		"fr",
	],
	en: [
		"en",
		"sp",
		"de",
		"no",
		"nb",
		"nob",
		"nor",
		"nn",
		"nno",
		"sv",
		"se",
		"swe",
		"fr",
	],
};

export const translate = (obj, locale) => {
	if (!obj) {
		return "";
	}
	for (let value of fallbackArr[locale]) {
		if (value in obj) {
			return obj[value];
		}
	}
	return Object.keys(obj).length > 0 ? obj[Object.keys(obj)[0]] : "";
};

const useFallbackTrans = obj => {
	const { state } = useContext(Store);
	return useMemo(() => {
		return translate(obj, state.settings.locale);
	}, [obj, state.settings.locale]);
};

export default useFallbackTrans;
