import React from "react";
import { Button as MUIButton } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import styled from "styled-components";

const Container = styled(MUIButton)``;

const Button = ({ label, onClick, fullWidth = false, arrow = false }) => {
	const onClickHandler = e => {
		e.stopPropagation();
		onClick && onClick();
	};

	return (
		<Container
			onClick={onClickHandler}
			endIcon={arrow ? <ArrowForward /> : false}
			fullWidth={fullWidth}
			variant={"contained"}
			color={"primary"}
		>
			{label}
		</Container>
	);
};

export default Button;
