import Typography from "@material-ui/core/Typography";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const NotFoundPage = () => (
	<Container>
		<Helmet>
			<title>Not Found - Museumsguiden.no</title>
		</Helmet>
		<Typography>Page not found</Typography>
	</Container>
);

const Container = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

export default NotFoundPage;
