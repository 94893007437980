import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Store } from "../../Store";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

import { ReactComponent as ListLeftIcon } from "../../assets/images/header_icons/list-left.svg";
import ButtonDecoration from "./buttonDecoration";
import Triangle from "./triangle";
import {
	text_sm,
	text_sm_bold,
	text_xs,
	text_xs_bold,
} from "../../style/styles";

const Container = styled.div`
	//position: absolute;
	//left: ${props => props.left};
	height: ${props => (props.sb_variant === "small" ? "60px" : "100%")};
	display: flex;
	&:last-of-type {
		padding-right: 18px;
	}
`;

const CustomButton = styled(Button)`
	position: relative;
	text-transform: none;
	font-size: ${props => (props.sb_variant === "big" ? "18px" : "14px")};
	font-weight: 400;
	line-height: ${props => (props.sb_variant === "big" ? "28px" : "20px")};
	letter-spacing: 0em;
	color: var(--Museumssvart);
	height: ${props => (props.sb_variant === "small" ? "60px" : "100%")};
	padding-left: ${props => (props.sb_variant === "medium" ? "9px" : "16px")};
	padding-right: ${props => (props.sb_variant === "medium" ? "9px" : "16px")};
	padding-bottom: ${props => (props.sb_variant === "medium" ? "9px" : "6px")};
	padding-top: ${props => (props.sb_variant === "medium" ? "9px" : "6px")};
	& .MuiButton-iconSizeMedium > *:first-child {
		font-size: ${props => (props.sb_variant === "big" ? "30px" : "25px")};
	}
`;

const FilterCount = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${props =>
		props.sb_variant === "big" ? "var(--blaze100)" : "var(--blaze500)"};
	width: 20px;
	height: 24px;
	color: ${props =>
		props.sb_variant === "big" ? "var(--Museumssvart)" : "#FFF"};
	${text_xs_bold}
	border-radius: 6px;
	margin-right: 5px;
	margin-left: ${props => (props.sb_variant === "medium" ? "0px" : "5px")};

	@media only screen and (min-width: 1024px) {
		${text_sm_bold}
	}
`;

const FilterText = styled.p`
	${text_xs}
	&::first-letter {
		text-transform: lowercase;
	}

	@media only screen and (min-width: 1024px) {
		${text_sm}
	}
`;

const SearchBarSelectorBtn = ({
	isCategory,
	selectorState,
	setSelectorState,
	sb_variant,
}) => {
	const { t } = useTranslation("common");
	const { state } = useContext(Store);
	//console.log(state);
	// [category, county]
	const clickHandler = () => {
		if (isCategory) {
			if (selectorState[0]) setSelectorState([0, 0]);
			else setSelectorState([1, 0]);
		} else {
			if (selectorState[1]) setSelectorState([0, 0]);
			else setSelectorState([0, 1]);
		}
	};

	// legg til usememo
	const getFilterCount = () => {
		if (isCategory) {
			if (
				state.settings.filtersCategories.length ===
				Object.keys(state.data.categoriesList).length
			) {
				return 0;
			}
			return state.settings.filtersCategories.length;
		} else {
			if (
				state.settings.filtersCounties.length === state.data.countiesList.length
			) {
				return 0;
			}
			return state.settings.filtersCounties.length;
		}
	};

	// legg til usememo
	const filterTextCategories = () => {
		const categoryFilterListLen = state.settings.filtersCategories.length;
		const categoryDataListLen = Object.keys(state.data.categoriesList).length;
		if (
			categoryDataListLen === categoryFilterListLen ||
			categoryFilterListLen === 0
		) {
			return ["", t("filterText.inAllCategories")];
		} else {
			return [
				t("filterText.in"),
				t("plurals.category", { count: categoryFilterListLen }),
			];
		}
	};

	// legg til usememo
	const filterTextCounties = () => {
		const countyFilterListLen = state.settings.filtersCounties.length;
		const countyDataListLen = state.data.countiesList.length;
		if (countyFilterListLen === 0) {
			return ["", t("filterText.inWholeCountry")];
		} else if (countyDataListLen === countyFilterListLen) {
			return ["", t("filterText.inAllCounties")];
		} else {
			return [
				t("filterText.in"),
				t("plurals.county", { count: countyFilterListLen }),
			];
		}
	};

	const filterTextGenerator = () => {
		if (isCategory) {
			return filterTextCategories();
		} else {
			return filterTextCounties();
		}
	};

	return (
		<Container sb_variant={sb_variant}>
			<CustomButton
				sb_variant={sb_variant}
				onClick={clickHandler}
				endIcon={
					(isCategory && selectorState[0]) ||
					(!isCategory && selectorState[1]) ? (
						<KeyboardArrowUpIcon />
					) : (
						<KeyboardArrowDownIcon />
					)
				}
			>
				{sb_variant === "small" && (
					<ButtonDecoration>
						<ListLeftIcon />
					</ButtonDecoration>
				)}
				<FilterText>
					{sb_variant !== "medium" ? filterTextGenerator()[0] : ""}
				</FilterText>
				{getFilterCount() > 0 && (
					<FilterCount sb_variant={sb_variant}>{getFilterCount()}</FilterCount>
				)}
				<FilterText>{filterTextGenerator()[1]}</FilterText>
				{sb_variant !== "small" &&
				((isCategory && selectorState[0]) ||
					(!isCategory && selectorState[1])) ? (
					<Triangle />
				) : null}
			</CustomButton>
		</Container>
	);
};

export default SearchBarSelectorBtn;
